<template>
	<div class="shop">
		<div>
			<!-- <van-search v-model="value" show-action placeholder="请输入搜索关键词" @search="onSearch(value)">
				<template #action>
					<div @click="onSearch(value)">搜索</div>
				</template>
			</van-search> -->
			<van-tabs v-model="active" sticky @click="toggle" color="#ffa000">
				<van-tab v-for="item in shopType" :title="item.name">
					<!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->
					<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10" 
						finished-text="我是有底线的" @load="onLoadList" style="padding:15px 15px 50px 15px;height: 100%;">

						<div v-for="item in goodsList" class="goods-item" @click="goShopDetail(item.id)">
							<!-- <van-image v-if="item.image" :src="imageUrl+item.image" alt="" class="goods-image"  width="100" height="88" />
							<van-image v-else src="" alt="" class="goods-image"  width="100" height="88" /> -->
							<img :src="imageUrl+ item.image" alt="" class="goods-image">
							<div class="shop-info">
								<span class="goods-name">{{item.name}}</span>
								<div class="shop-address-item">
									<div>
										<!-- <van-icon name="location" /> -->
										<span class="shop-address">{{item.address}}</span>
									</div>
									<span class="shop-address">100m</span>
								</div>
							</div>
						</div>
					</van-list>

					<!-- </van-pull-refresh> -->
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>
<script>
	import {
		index
	} from '@/api'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		getIconList,

	} from '@/api/index'
	import {
		getShopList,
		getSearch
	} from '@/api/shop'
	import {
		Toast
	} from 'vant'
	export default {
		data() {
			return {
				imageUrl: this.url.imageUrl,
				shopType: '',
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				// reload:this.reload，
				active:0
			}
		},
		mounted() {
			this.getShopType()
			this.getShopList()
		},
		created() {
			
		},
		methods: {
			
			goShopDetail(id) {
				this.$router.push({
					path: '/shopDetail',
					query: {
						id: id
					}
				})
			},
			toggle(name) {
				this.type = name + 1
				this.page = 1
				this.limit = 10
				this.goodsList = []
				this.getShopList()
			},
			getShopType() {
				getIconList().then((res) => {
					this.shopType = res.data
				})
			},
			getShopList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.page = this.page
				params.limit = this.limit
				params.lng = 113.6496429
				params.lat = 34.7566109
				params.trade_id = this.type
				getShopList(params).then((res) => {
					// console.log(res)
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getShopList()
			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.shop {
		.van-tabs {
			background-color: #f6f6f6;
		}

		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;
				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			height: 88px;
			border-radius: 5px;
			margin-bottom: 10px;
			padding: 5px 15px;
			justify-content: flex-start;

			.goods-image {
				width: 88px;
				height: 88px;
				border-radius: 8px !important;
			}

			.shop-info {
				margin-left: 10px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				width: 60%;

				.goods-name {
					font-size: 16px;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

				}

				.shop-address-item {
					// display: flex;
					// align-items: center;
					// justify-content: space-between;

					.shop-address {
						font-size: 12px;
						color: #666666;
					}
				}

			}

		}
	}
</style>
